/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Ropa+Sans &display=swap');:root {
    --whitecolor: #efefef;
    --buttonblue: #121413;
    --rederror: #b52b2b;
    --pcmaincolor: #0F1848;

}
body {
    overflow-x: hidden;
}
button{
    cursor: pointer;
}
* {
    padding: 0;
    margin: 0;
    position: relative;
    box-sizing: border-box;
    font-family: 'Ropa Sans', sans-serif;
}

h2 {
    span {
        font-size: 0.7em;
        font-weight: 100;
    }
}
select {
    padding: 0.4em;
    max-height: 2.4em;
}
.col-4 {
    grid-template-columns: repeat(4, 1fr);
}
.col-2 {
    grid-template-columns: repeat(2, 1fr);
}
.col-2-auto {
    grid-template-columns: repeat(2, auto);
}

.col-2-L {
    grid-template-columns: 1.5fr 0.5fr;
}
.col-3 {
    grid-template-columns: repeat(3, 1fr);
}
.col-3-slim-center {

    grid-template-columns: 0.9fr 0.2fr 0.9fr
}
.col-4 {
    grid-template-columns: repeat(4, 1fr);
}
.col-6 {
    grid-template-columns: repeat(6, 1fr);
}
.grid-menu {
    grid-template-columns: 0.3fr 1.7fr;
}
.error-text{
    color: var(--rederror);
    font-family: monospace;
}
.grid_center_calc-wh {
    width: calc(100vw - 35em);
    display: grid;
    align-content: center;
    justify-content: center;
    height: calc(100vh - 11rem);
}
.main-container,
.grid {
    display: grid;
    place-content: center;
}

.circlebtn {
    clip-path: circle(28%);
    padding: 2em !important;
    cursor: pointer;
    transition: rotate 300ms ease-in-out;
    &:hover {
        rotate: 90deg;
        background: #7f8482;
    }
}
.width-500px {
    width: 500px;
}
.attention {
    position: relative;
    width: fit-content;
}
.attention:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: calc(120px - 78px);
    border: 2px solid var(--rederror);
    width: -moz-fit-content;
    width: 120px;
}
.z-index-front {
    z-index: 999;

}
.button-md {
    position: relative;
    button {
        scale: 0;
        transform: scale(0);
        position: relative;
        cursor: pointer;
        width: 6em;
        font-size: 1.1rem;
        animation: enter 500ms forwards;
        transition: scale, animation 400ms ease-in-out;
    }
    button:hover {
        scale: 0.8;
        transform: scale(0.8);
    }
    button:disabled {
        cursor: not-allowed;
        background-color: #92979a !important;
        min-width: 9rem !important;
        color: #ccc !important;
    }
    button:disabled:hover {
        scale: 1
    }

    button:disabled:hover:before {
        content: "Form invalid!";
        position: absolute;
        bottom: 4em;
        left: 0.5em;
        background: rgba(255, 0, 0, 0.4117647059);
        box-shadow: -2px 0 9px 1px rgba(0, 0, 0, 0.4);
        width: 109px;
        flex-flow: nowrap;
        padding: 0.4em;
        font-size: 0.7rem;
        transition: content, background, width 400ms ease-in-out;
        color: #f4f4f4;
    }
}


.notification {
    position: relative;

}
.notification::after {
    content: "dot";
    position: absolute;
    width: 10px;
}
.flex {

    display: flex;
}
.full-view {
    width: 100vw;
}
.loader {
    padding: 0.3em 0;
    background: #2b4a9a26;
    max-height: 0.2em;
    z-index: 1;
    position: relative;
}
@keyframes enter {
    from {
        scale: 0.3;
        transform: scale(0.3);
    }
    to {
        scale: 1;
        transform: scale(1);
    }
}
@keyframes barloaderanimation {
    10% {
        width: 40%;
    }
    50% {
        width: 60%;
    }
    60% {
        width: 40%;
    }
    75% {
        width: 68%;
    }
    80% {
        width: 78%;
    }
    100% {
        width: 100%;
    }
}
.md-controls {
    input {
        padding: 0.4em;
        font-size: 1.4rem;
        height: fit-content;
    }

    button {
        cursor: pointer;
        padding: 0.4em 1em;
        text-align: center;
        font-size: 1.3rem;
        border: 0;
        background: #fff;
        background: var(--buttonblue);
        box-shadow: 0 1px 6px -2px var(--buttonblue);
        color: #fff;
    }
    span {
        padding: 1em 0;
        color: var(--rederror);
    }
}
.xlcontrols {
    input {
        padding: 0.4em;
        font-size: 1.4rem;
    }
    button {
        padding: 1em 5em;
        text-align: center;
        font-size: 1.3rem;
        background: #fff;
        cursor: pointer;
    }
}
.popup {
    position: absolute;
}
.height-400 {
    height: 400px;
}
.scroll-y {
    overflow-y: scroll;
}
.loader:before {
    content: "";
    background: var(--pcmaincolor);
    position: absolute;
    width: 100%;
    opacity: 70%;
    padding: 0.18em 0;
    top: 0.1em;
    left: 0;
}

.loading {
    position: relative;
    width: inherit;
}
.loading::before {
    background: var(--pcmaincolor);
    animation: barloaderanimation 2s forwards infinite;
    transition-property: animation;
    transition-delay: 400ms;
    transition-timing-function: linear;
}

.slim-title-bar {
    margin: 0.5em 0;
    color: var(--whitecolor);
    background-color: #0F184891;
    padding: 0.4em;
    div {
        text-align: right;
    }
    img {
        scale: .4;
        width: 1em;
        filter: invert(100%) sepia(7%) saturate(81%) hue-rotate(337deg) brightness(115%) contrast(87%);
        animation: enter 700ms forwards;
        transition: scale 400ms ease-in-out;

    }

}

.title-bar {
    margin: 1em 0;
    color: var(--whitecolor);
    border-radius: 10px 10px 0 0;
    box-shadow: 2px 2px 6px 1px #ccc;
    background-color: var(--pcmaincolor);
    padding: 1em;
    div {
        text-align: right;
    }
    img {
        scale: 0;
        width: 1.4em;
        filter: invert(100%) sepia(7%) saturate(81%) hue-rotate(337deg) brightness(115%) contrast(87%);
        animation: enter 700ms forwards;
        transition: scale 400ms ease-in-out;

    }
}
.max-width-200px {
    max-width: 200px;
}
.margin-top-bottom-1em {
    margin: 1em 0;
}
.form-control {
    margin: 1em 0;
}
.minheight-70vh {
    min-height: 70vh;
}
.form-max-control-70 > input {
    max-width: 70%;
}
.justify-items-end {
    justify-items: end;
}
.toupper {
    text-transform: uppercase;
}
.main-container {
    max-width: 1500px;
    margin: 0 auto;
}

.textalign-center {
    text-align: center;
    place-items: center;
    justify-content: center;
}
.min-width-280px {
    min-width: 280px;
}
.full-width {
    width: 100%;
    height: 100%;
}
.padding-top-2em {
    padding-top: 2em;
}
.padding-1em-top {
    padding-top: 1em;
}
.padding-2em {
    padding-top: 2em;
}

.margin-left-15 {
    margin-left: 15em;
}
.col-2-left-80 {
    grid-template-columns: 1.4fr 0.6fr;
}
.min-width-40em {
    min-width: 40em
}


.icongrid .active-bee,
.bee:hover {
    background: linear-gradient(#ecc905 ,#32323584 90%);
    color: #f4f1f1;
}
.icongrid .active-bear,
.bear:hover {
    background: linear-gradient(#f4f1f1 ,#bb3d3d 99%);
    color: #f4f1f1;
}
.icongrid .active-jellyfish,
.jellyfish:hover {
    background: linear-gradient(#f4f1f1 20px, #ecc905);
    color: #f4f1f1;
}
.icongrid .active-bag,
.bag:hover {
    background: linear-gradient(#ededed 20px, #0575ec);
    color: #f4f1f1;
}
.icongrid .active-bells,
.bells:hover {
    animation: foldin 300ms forwards;
    transition: animation 500ms ease-in-out;
    background: linear-gradient(#ededed 10px, #c10404);
    color: #f4f1f1;


}
.icongrid {
    text-align: center;
}
.right-margin-5em {
    margin-right: 5em;
}
.icongrid > a {
    display: grid;
    justify-content: center;
    transform-origin: top;
    transition: all 500ms ease-in-out;
    padding: 1.5em;
    cursor: pointer;
}

.menu-container {
    gap: 2em;
    width: calc(100vw - 29em);
    .menu-item {
        position: relative;
        cursor: pointer;
    }
    .menu-item::before {
        content: "";
        position: absolute;
        inset: 0;
    }
    .menu-item:hover > img {
        scale: (0.8);
        transition: scale 300ms ease-in-out;
    }
    .menu-item:hover > span {
        color: rgb(119, 119, 169);
        transition: color 300ms ease-in-out;
    }
    .menu-item > img {
        width: 80px;
        margin: 10px 0;
    }

}
.gap-2px {
    gap: 2px;
}
.gap-2em {
    gap: 2em;
}
.padding-4em-top{
    padding-top: 4em ;
}

.padding-4em {
    padding: 4em;
}
.padding-4em-sides {
    padding: 0 4em;
}
.padding-2em-top {
    padding: 2em 0;
}
.padding-2em-sides {
    padding: 0 2em !important;
}
.padding-2em {
    padding: 2em;
}
.margin-1em-sides {
    margin: 0 1.3em;
}
.margin-top-4em {
    margin-top: 4em;
}

.linethrough {
    text-decoration: line-through
}
.margin-top-2em {
    margin-top: 2em;
}
.boxshadow-lightcolor {
    background: #f2f0f44a;
    box-shadow: 1px 1px 6px #333;
}
.red-context {
    color: var(--rederror);
}

.filtersvg {
    scale: 0;
    animation: enter 1s forwards;
    animation-delay: 1s;
    cursor: pointer;
    filter: invert(100%) sepia(7%) saturate(81%) hue-rotate(337deg) brightness(115%) contrast(87%);
    transition: opacity, filter, scale 400ms ease-in-out;
}
.filtersvg-pc_color {
    scale: 0;
    animation: enter 1s forwards;
    animation-delay: 1s;
    filter: invert(14%) sepia(15%) saturate(3751%) hue-rotate(198deg) brightness(88%) contrast(107%);
    transition: animation opacity, filter, scale 1s ease-in-out;
}
.filtersvg:hover {
    opacity: 70%;
    filter: invert(24%) sepia(99%) saturate(1219%) hue-rotate(339deg) brightness(94%) contrast(91%);
    scale: .8;
}
.fix-middle {
    position: fixed !important;
    left: 40%;
}
.fix-bottom-right {
    position: fixed;
    bottom: 1em;
    right: 1em;
}
.sticky-middle {
    position: sticky !important;
    left: 40%;
}
.absolute-middle {
    position: absolute !important;
    left: calc(80vw / 2);
}
.fixed-end
{
    position: fixed;
    right: 0;
}
.notification-container {
    animation: enter 700ms forwards;
    transition: animation 400ms ease-in-out;
    padding: 1em;
    min-width: 200px;
    width: 500px;
    border-radius: 0.2rem;
    text-align: center;
    margin: 0 auto;
    z-index: 999;
}
.error-container {
background : #ef4565;
   // border: 2px solid #580c0c8f;
}
.invalid {
    color: var(--rederror);
}
.edit-icon {
    width: 25px;
    height: 25px;
    background-size: cover;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAedJREFUWEelV1mWBCEIw/sf2nkqLkBYqqe+phUDQghOo+xrRNTZyPub3g0i/VO6aNRap86Y4+T8FMT8PVb79q4NgBdjIsDRbmM/IkQ2xPYrG3vP8egGQsMhX+lxY4rgA4B6baBGM63nbAWkTftrWciyjSDK1GutafIEDGlocZ/0ZcRV+wtL5ZyzdTJQyZjvd8B0op6gaHqNY5BPKc7tDnQ+4ehzD9QFj/NyVqChvO4poOaCVgEv+rE+vq1JVzwA4hYZJprgolpj3CqVPRYEnZQQ9nAAyuEHtkdccKWWOyFRj6D9cD1FmTIe7dKyvq4qh4cqUgdtMOoMIHKoqxvAMEWlxYcM2LmYHUYzzKWNA/aUQB6VbQd4AAA/BTxTr4ToMvl5BxQnxTUrlOAxORlYa/YOUIACOdAjwVOYLWo8jIzGFRVAw3t12TNP6OiRB3FvnIP7KFzFQdPvPemxAaF/Zg4n5xJmDgjT+gLXF7NFQzthpJdfg1xEl081EL9aEuNaBYepUWBCKA9n87eL/u+UFSLVo/cVrvrbzPp6IJquKj02Ak8jPPGx+fYRlBDFNc7vqFqj8BixJUhUwWCCdjwqN8vktCCfAwFoF/V7Z6MdIbsBYEW0kp2HF9dhBmD6GLxmy3MeRBT9T/UHeETbK2BcuOYAAAAASUVORK5CYII=');
}
.success-container {
background : #d6f5e8

    //border: 2px solid #023d368f;
}
.form-display {
    padding: 0.2em;
    span:nth-child(1) {
        font-size: 0.85rem;
        font-weight: 100;
        text-transform: uppercase;
    }
}
.placebottom {
    padding: 0.8em;
    position: fixed;
    bottom: 0;
    left: 21em;
    width: calc(100% - 24em);
    background: #0F1848;
    background: #0F3D4845;
    box-shadow: 1px 1px 1px #143c34;
}
.form-control {
    display: grid;

    gap: 1em;
    .form-input {
        display: grid;
        // grid-template-rows: 20px 1fr 20px;
        label {
            font-size: 0.8rem;
            text-align: left;
            margin: 0;
        }
        input {
            height: 36px;
        }
        input,
        textarea {
            padding: 0.4em;
            color: #807777;
            border: #ccccccad 2px solid;
        }
        span {
            color: var(--rederror);
            padding: 5px 2px;
            font-size: 0.9rem;
        }
    }

    button {
        min-width: 8rem;
        padding: 0.5em 1em;
        background: var(--buttonblue);
        outline: none;
        border: none;
        color: var(--whitecolor);
        font-family: 'Ropa Sans', sans-serif;
        margin: 0 auto;
    }
    .container {
        padding: 2em 0.5em;
    }
}
.greybtn {
    background-color: #74828E !important;
    width: 8rem !important;
}
.greenbtn {
    background-color: var(--rederror);
    width: 8rem !important;
}
.gap-ms {
    gap: 0.4em;
}
.place-item-center {
    place-items: center;
}
.place-content-end {
    place-content: end;
}
.padding-right-ms {
    padding-right: 5em;
}
.padding-right-sm {
    padding: 0 1rem;
}
.justify-items-center {
    justify-items: center
}
.place-content-baseline {
    place-content: baseline;
}
.padding-rt-4 {
    padding-right: 4px;
}
.options-list {
    padding: 1em 0 !important;
    background-color: red;
}

.full-height {
    width: 100%;
}
.bg-main {
    background-color: #CED4F036;
    box-shadow: 1px 1px 3px #001;
}
.align-right {
    text-align: right;
}
.sidebar {
    position: relative;
    display: grid;
    place-content: last baseline;
    justify-content: start;
    justify-content: end;


    a {
        scale: 0.8;
        display: grid;
        grid-template-columns: 0.4fr 1.6fr;
        margin: 1rem 0;
        place-content: center;
        padding: 0.2em 5em;
        transform-origin: right;
        transition: box-shadow, scale 300ms ease-in-out;
        color: #ccc;

        img {
            width: 50px;
        }
    }
    a[href] {
        color: var(--pcmaincolor);
        cursor: pointer;
    }
    a[href]:hover {
        box-shadow: 1px 1px 4px #ccc;
        scale: .9;
    }
}

.tile-display{
    grid-template-columns: repeat(auto-fill, 200px);
    padding: 1.3em 2em 0.3em 1em;
}



.overlay-modal{
    position: fixed;
    display: grid;
    place-content: center;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(222, 215, 215, 0.4);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: 999;

h3{
    padding: .5rem;
    font-weight: 500;
    font-size: 1.7rem;
    }
.model-content
{
    width: 50vw;
    border-radius: .3em;
    background-color: var(--whitecolor);
    opacity: .89;
    text-align: center;
    color: var(--pcmaincolor);
    box-shadow: 8px 2px 13px 2px #ccc;

    .underlineborder{
        position: relative;

        
    }
    .underlineborder::after{
        content: "";
        position: absolute;
        top: 1px;
        left: 0px;
        border: 2px;
        width: fit-content
    }
    .header{
       position: relative;
       padding: 1em;
      
    }
    .content{padding: 1em;
        p{
            padding: .3em;
            span{
                color: var(--rederror);
            }
        }
        h3.invalid{
            color: var(--rederror) !important;
        }
    }
    .action{
        padding: 2em 7em;
    }
  
}
}
.closebtn{
    position: absolute;
    right: 10px;
    top: 0%;
    font-weight: 500;
    font-size: 1.5rem;
    padding: .4em;
    z-index: 999;
}




@keyframes fillin {
    from {
        box-shadow: inset 1px 2px 4px var(--pcmaincolor);
    }
    to {
        box-shadow: inset 1px 2px 17px var(--pcmaincolor);
    }
}
